import React, { Fragment } from "react";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Chip, InputLabel, Radio, RadioGroup, Select, Switch, TextField, Button } from "@material-ui/core";
import { DatePicker, DateTimePicker, TimePicker } from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash"
import { UPDATE_FORM, UPDATE_FORM_FIELDS_VALUES } from "../../reducers/form";
import { IonButton, IonIcon } from "@ionic/react";
import { cloudUploadOutline, fileTrayFullOutline, trashOutline } from "ionicons/icons";
import ModalContainer from "../ModalContainer/ModalContainer";
import { ContainerService } from "../../services/container";
import * as CONSTS from '../../services/const'

export const TYPE_ID = 'id'
export const TYPE_TEXT = 'text'
export const TYPE_PASSWORD = 'password'
export const TYPE_SELECT = 'select'
export const TYPE_RADIO = 'radio'
export const TYPE_DATETIMEPICKER = 'datetimepicker'
export const TYPE_DATEPICKER = 'datepicker'
export const TYPE_TIMEPICKER = 'timepicker'
export const TYPE_TOGGLE = 'toggle'
export const TYPE_AUTO_COMPLETE = 'autocomplete'
export const TYPE_FILEUPLOAD = 'fileupload'

const containerService = new ContainerService().build()


const cssLabel = { display: "flex", flexDirection: "column" }
const cssError = { color: "red" }

const Field: React.FC<any> = (params: any) => {
    const dispatch = useDispatch()
    const input = React.createRef();

    const createField = (field: any, formik: any, readOnly: any, formName: string) => {
        const fieldName = field.name
        switch (field.type) {
            case TYPE_ID:
                return <TextField
                    hidden={true}
                    key={fieldName}
                    id={formik.errors[fieldName] ? 'outlined-error-helper-text' : 'outlined-helperText'}
                    helperText={formik.errors[fieldName] ? formik.errors[fieldName] : ''}
                    variant="outlined"
                    error={formik.errors[fieldName] ? true : false}
                    label={field.label}
                    value={formik.values[fieldName]}
                    disabled={true}
                    onChange={e => formik.setFieldValue(fieldName, e.target.value)}
                    fullWidth
                />
            case TYPE_PASSWORD:
            case TYPE_TEXT:
                return <TextField
                    key={fieldName}
                    id={formik.errors[fieldName] ? 'outlined-error-helper-text' : 'outlined-multiline-flexible'}
                    helperText={formik.errors[fieldName] ? formik.errors[fieldName] : ''}
                    error={formik.errors[fieldName] ? true : false}
                    label={field.label}
                    multiline={field.multiline || false}
                    variant="outlined"
                    type={field.type}
                    value={formik.values[fieldName]}
                    disabled={field.readOnly || false}
                    onChange={e => formik.setFieldValue(fieldName, e.target.value)}
                    fullWidth
                />
            case TYPE_AUTO_COMPLETE:
                const getOptionSelected = (option: any, value: any) => true
                return <FormControl variant="outlined" error={formik.errors[fieldName] ? true : false} key={fieldName} style={{width: '100%'}}>
                    <Autocomplete
                        fullWidth={true}
                        ref={input}
                        noOptionsText={'Nenhum registro encontrado.'}
                        multiple={field.multiple || false}
                        id="outlined-helperText"
                        value={formik.values[fieldName]}
                        getOptionSelected={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => {
                            if (field.multiple) {
                                const newArrayValue = [...formik.values[fieldName], ...newValue]
                                formik.setFieldValue(fieldName, newValue)
                            } else {
                                formik.setFieldValue(fieldName, newValue)

                                if(field.updateValue) {
                                    dispatch({
                                        type: UPDATE_FORM_FIELDS_VALUES,
                                        name: formName,
                                        field: {
                                            name: field.name,
                                            value: newValue
                                        }
                                    })
                                }
                            }

                            //usado para autocomplete poder atualizar e outros campos usarem ele
                            /*
                            dispatch({
                                type: UPDATE_FORM_FIELDS_VALUES,
                                name: formName,
                                field: {
                                    name: field.name,
                                    value: newValue
                                }
                            })
                            */
                        }}
                        onKeyUp={debounce((e: any) => {
                            if (input) {
                                const inputAutoComplete: any = input?.current
                                if (inputAutoComplete || inputAutoComplete?.querySelector('input') || inputAutoComplete?.querySelector('input')?.value) {
                                    dispatch({
                                        type: UPDATE_FORM,
                                        name: formName,
                                        [`SearchOn_${formName}_${field.name}`]: inputAutoComplete.querySelector('input').value
                                    })
                                }
                            }
                        }, 1000)}
                        options={field.options || []}
                        getOptionLabel={(option: any) => option?.label || option?.id || ""}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.label}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} label={field.label} variant="outlined" />
                        )}
                    />
                    <FormHelperText>{formik.errors[fieldName] ? formik.errors[fieldName] : ''}</FormHelperText>
                </FormControl>
            case TYPE_SELECT:
                return <FormControl variant="outlined" error={formik.errors[fieldName] ? true : false} key={fieldName} style={{width: '100%'}}>
                    <InputLabel htmlFor="outlined-age-native-simple">{field.label}</InputLabel>
                    <Select
                        native
                        value={formik.values[fieldName]}
                        onChange={(e: any) => {
                            let parsed

                            try {
                                parsed = JSON.parse(e.target.value)
                            } catch (err) {
                                parsed = e.target.value
                            }

                            formik.setFieldValue(fieldName, parsed)
                        }}
                        error={formik.errors[fieldName] ? true : false}
                        label={field.label}
                        inputProps={{
                            name: fieldName,
                            id: 'outlined-age-native-simple',
                        }}
                    >
                        {/* <option key={-1} value={''}> Selecione...</option> */}
                        <option key={-1} value={''}></option>
                            )
                            {field.options.map((option: any) => <option key={option.value} value={option.value}>{option.label}</option>)}
                    </Select>
                    <FormHelperText>{formik.errors[fieldName] ? formik.errors[fieldName] : ''}</FormHelperText>
                </FormControl>
            case TYPE_RADIO:
                return <FormControl component="fieldset" variant="outlined" error={formik.errors[fieldName] ? true : false} key={fieldName}>
                    <FormLabel htmlFor="outlined-age-native-simple" component="legend">{field.label}</FormLabel>
                    <RadioGroup /*row para deixar em row os radio*/ aria-label="position" name="position" defaultValue="top" value={formik.values[fieldName]} onChange={e => formik.setFieldValue(fieldName, e.target.value)} >
                        {field.options.map((option: any, iOption: number) => {
                            return <FormControlLabel key={`${fieldName}_${iOption}`}
                                value={option.value}
                                control={<Radio color="primary" />}
                                label={option.label}
                                labelPlacement="end" />
                        })}
                    </RadioGroup>
                    <FormHelperText>{formik.errors[fieldName] ? formik.errors[fieldName] : ''}</FormHelperText>
                </FormControl>
            case TYPE_DATETIMEPICKER:
                return <FormControl component="fieldset" variant="outlined" error={formik.errors[fieldName] ? true : false} key={fieldName}>
                    <DateTimePicker
                        inputVariant="outlined"
                        id={formik.errors[fieldName] ? 'outlined-error-helper-text' : 'outlined-helperText'}
                        helperText={formik.errors[fieldName] ? formik.errors[fieldName] : ''}
                        error={formik.errors[fieldName] ? true : false}
                        label={field.label}
                        value={formik.values[fieldName]}
                        disabled={readOnly}
                        onChange={e => formik.setFieldValue(fieldName, e)}
                        format={field.format || 'dd/MM/yyyy HH:mm'}
                        fullWidth
                    />
                    <FormHelperText>{formik.errors[fieldName] ? formik.errors[fieldName] : ''}</FormHelperText>
                </FormControl>
            case TYPE_DATEPICKER:
                return <FormControl component="fieldset" variant="outlined" error={formik.errors[fieldName] ? true : false} key={fieldName}>
                    <DatePicker
                        inputVariant="outlined"
                        id={formik.errors[fieldName] ? 'outlined-error-helper-text' : 'outlined-helperText'}
                        helperText={formik.errors[fieldName] ? formik.errors[fieldName] : ''}
                        error={formik.errors[fieldName] ? true : false}
                        label={field.label}
                        value={formik.values[fieldName]}
                        disabled={readOnly}
                        onChange={e => formik.setFieldValue(fieldName, e)}
                        format={field.format || 'dd/MM/yyyy'}
                        fullWidth
                    />
                    <FormHelperText>{formik.errors[fieldName] ? formik.errors[fieldName] : ''}</FormHelperText>
                </FormControl>
            case TYPE_TIMEPICKER:
                return <FormControl component="fieldset" variant="outlined" error={formik.errors[fieldName] ? true : false} key={fieldName}>
                    <TimePicker
                        inputVariant="outlined"
                        id={formik.errors[fieldName] ? 'outlined-error-helper-text' : 'outlined-helperText'}
                        helperText={formik.errors[fieldName] ? formik.errors[fieldName] : ''}
                        error={formik.errors[fieldName] ? true : false}
                        label={field.label}
                        value={formik.values[fieldName]}
                        disabled={readOnly}
                        onChange={e => formik.setFieldValue(fieldName, e)}
                        format={field.format || 'HH:mm'}
                        fullWidth
                    />
                    <FormHelperText>{formik.errors[fieldName] ? formik.errors[fieldName] : ''}</FormHelperText>
                </FormControl>
            case TYPE_TOGGLE:
                return <FormControlLabel
                    control={<Switch
                        value={formik.values[fieldName]}
                        disabled={readOnly}
                        onChange={(e: any) => formik.setFieldValue(fieldName, e)}
                        key={fieldName}
                        color="primary" />}
                    label={field.label}
                    labelPlacement="top"
                />
            case TYPE_FILEUPLOAD:
                return <Fragment key={fieldName} >
                    <input
                        multiple={field.multiple || true}
                        color="primary"
                        type="file"
                        key={`${fieldName}_file`}
                        onChange={async (e: any) => {
                            const files: any = Array.from(e.target.files)
                            const newValue = formik.values[fieldName]
                            for (const file of files) {
                                if (!formik.values[fieldName].includes(file?.name)) {
                                    newValue.push(file.name)
                                    await containerService.apiService.fileUpload(file)
                                }
                            }

                            formik.setFieldValue(fieldName, newValue)
                        }}
                        id="icon-button-file"
                        style={{ display: 'none' }} />
                    <label htmlFor="icon-button-file" key={`${fieldName}_label`}>
                        <Button
                            variant="contained"
                            component="span"
                            size="large"
                            color="primary"
                        >
                            <IonIcon icon={cloudUploadOutline} /> Upload ({formik.values[fieldName]?.length || 0})
                        </Button>
                    </label>
                    {formik.values[fieldName].length > 0 ?

                        <ModalContainer key={`${fieldName}_modal`} style={{ width: "10px", height: "42px", marginLeft: "5px" }} buttonLabel={<IonIcon icon={fileTrayFullOutline} />}>
                            <div style={{ overflowY: "auto", overflowX: "hidden" }}>
                                <ul style={{ width: "100%", listStyleType: "none" }}>
                                    {(formik.values[fieldName] || []).map((file: any, index: number) => <>
                                        <li>
                                            <IonButton color="danger" size="small" onClick={e => {
                                                const newValue = formik.values[fieldName]
                                                newValue.splice(index, 1)
                                                formik.setFieldValue(fieldName, newValue)
                                            }}>
                                                <IonIcon icon={trashOutline} />
                                            </IonButton> <a target="blank" href={`${CONSTS.API_URL}${CONSTS.API_URL_FILES_DOWNLOAD}/${encodeURI(file)}`}> {file} </a>
                                        </li>
                                    </>)}
                                </ul>
                            </div>
                        </ModalContainer>

                        : null}
                </Fragment>
        }
    }
    return (
        <>
            {createField(params.field, params.formik, params.readOnly, params.formName)}
        </>
    )
}

export default Field;